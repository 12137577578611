// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  min-height: 75vh;
}

.parent {
  position: relative;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.item {
  position: absolute;
  left: 2.5%;
  top: 45%;
  height: 10%;
  width: 10%;
  transform-origin: 470% 50%;
}

.btn-saeed {
  background-color: #2D896E;
  color: white;
}

.saeed-color {
  color: #2D896E;
}

.saeed-bg {
  background-color: #2D896E;
}

#logo {
  background: linear-gradient(90deg, #2D896E, #091515);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

.sign-in {
  background-color: #2D896E !important;
  font-size: 22px !important;
  font-weight: bold !important;
  border: none !important;
}
.sign-in:hover {
  background-color: #295f4e !important;
  color: whitesmoke !important;
  box-shadow: 0 0 8px 4px #B2B2B2da; 
}

.arrange {
  /* background-color: rgba(255, 0, 0, 0.233); */
  width: 100%;
  height: 100%;
}

.arrange .t{
  height: 30%;
  width: 50%;
  /* background-color: blue; */
}
.arrange .l{
  height: 100%;
  width: 25%;
  /* background-color:pink; */
}
.arrange .r{
  height: 100%;
  width: 25%;
  /* background-color:yellowgreen; */
}

.arrange>*>*{max-height: 130px !important;}
`, "",{"version":3,"sources":["webpack://./src/media/css/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,WAAW;EACX,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oDAAoD;EACpD,6BAA6B;EAC7B,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,4BAA4B;EAC5B,uBAAuB;AACzB;AACA;EACE,oCAAoC;EACpC,4BAA4B;EAC5B,iCAAiC;AACnC;;AAEA;EACE,8CAA8C;EAC9C,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,UAAU;EACV,4BAA4B;AAC9B;AACA;EACE,YAAY;EACZ,UAAU;EACV,2BAA2B;AAC7B;AACA;EACE,YAAY;EACZ,UAAU;EACV,kCAAkC;AACpC;;AAEA,aAAa,4BAA4B,CAAC","sourcesContent":["main {\n  min-height: 75vh;\n}\n\n.parent {\n  position: relative;\n  border: 4px solid rgba(0, 0, 0, 0.2);\n  border-radius: 50%;\n}\n\n.item {\n  position: absolute;\n  left: 2.5%;\n  top: 45%;\n  height: 10%;\n  width: 10%;\n  transform-origin: 470% 50%;\n}\n\n.btn-saeed {\n  background-color: #2D896E;\n  color: white;\n}\n\n.saeed-color {\n  color: #2D896E;\n}\n\n.saeed-bg {\n  background-color: #2D896E;\n}\n\n#logo {\n  background: linear-gradient(90deg, #2D896E, #091515);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  display: block;\n}\n\n.sign-in {\n  background-color: #2D896E !important;\n  font-size: 22px !important;\n  font-weight: bold !important;\n  border: none !important;\n}\n.sign-in:hover {\n  background-color: #295f4e !important;\n  color: whitesmoke !important;\n  box-shadow: 0 0 8px 4px #B2B2B2da; \n}\n\n.arrange {\n  /* background-color: rgba(255, 0, 0, 0.233); */\n  width: 100%;\n  height: 100%;\n}\n\n.arrange .t{\n  height: 30%;\n  width: 50%;\n  /* background-color: blue; */\n}\n.arrange .l{\n  height: 100%;\n  width: 25%;\n  /* background-color:pink; */\n}\n.arrange .r{\n  height: 100%;\n  width: 25%;\n  /* background-color:yellowgreen; */\n}\n\n.arrange>*>*{max-height: 130px !important;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
