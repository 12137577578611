// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --fold: rgb(192, 192, 192); 
  --check: rgb(0, 153, 255);
  --call: rgb(89, 210, 89);
  --raise: orange;
  --allin: tomato;
}
.poker-actions{
  width: 100%;
  margin: 0;
  height: 15vh;
  position: absolute;
  top: 80%;right: 0;
  z-index: 300;

}
.poker-actions .action-btn>*{
  width: 120px;
  margin: auto;
  /* background-color: red; */
}
`, "",{"version":3,"sources":["webpack://./src/media/css/poker-actions.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,wBAAwB;EACxB,eAAe;EACf,eAAe;AACjB;AACA;EACE,WAAW;EACX,SAAS;EACT,YAAY;EACZ,kBAAkB;EAClB,QAAQ,CAAC,QAAQ;EACjB,YAAY;;AAEd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[":root {\n  --fold: rgb(192, 192, 192); \n  --check: rgb(0, 153, 255);\n  --call: rgb(89, 210, 89);\n  --raise: orange;\n  --allin: tomato;\n}\n.poker-actions{\n  width: 100%;\n  margin: 0;\n  height: 15vh;\n  position: absolute;\n  top: 80%;right: 0;\n  z-index: 300;\n\n}\n.poker-actions .action-btn>*{\n  width: 120px;\n  margin: auto;\n  /* background-color: red; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
