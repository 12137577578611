export const TABLE_LIST_REQUEST = 'TABLE_LIST_REQUEST'
export const TABLE_LIST_SUCCESS = 'TABLE_LIST_SUCCESS'
export const TABLE_LIST_FAIL = 'TABLE_LIST_FAIL'

export const TABLE_DETAILS_REQUEST = 'TABLE_DETAILS_REQUEST'
export const TABLE_DETAILS_SUCCESS = 'TABLE_DETAILS_SUCCESS'
export const TABLE_DETAILS_FAIL = 'TABLE_DETAILS_FAIL'



