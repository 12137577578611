export const GAME_DETAILS_REQUEST = 'GAME_DETAILS_REQUEST'
export const GAME_DETAILS_SUCCESS = 'GAME_DETAILS_SUCCESS'
export const GAME_DETAILS_FAIL = 'GAME_DETAILS_FAIL'

export const GAME_ENTER_REQUEST = 'GAME_ENTER_REQUEST'
export const GAME_ENTER_SUCCESS = 'GAME_ENTER_SUCCESS'
export const GAME_ENTER_FAIL = 'GAME_ENTER_FAIL'

export const GAME_LEAVE_REQUEST = 'GAME_LEAVE_REQUEST'
export const GAME_LEAVE_SUCCESS = 'GAME_LEAVE_SUCCESS'
export const GAME_LEAVE_FAIL = 'GAME_LEAVE_FAIL'

export const GAME_NEXT_ROUND_REQUEST = 'GAME_NEXT_ROUND_REQUEST'
export const GAME_NEXT_ROUND_SUCCESS = 'GAME_NEXT_ROUND_SUCCESS'
export const GAME_NEXT_ROUND_FAIL = 'GAME_NEXT_ROUND_FAIL'

export const GAME_ACTION_REQUEST = 'GAME_ACTION_REQUEST'
export const GAME_ACTION_SUCCESS = 'GAME_ACTION_SUCCESS'
export const GAME_ACTION_FAIL = 'GAME_ACTION_FAIL'
